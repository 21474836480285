import axios from '../libs/gadgetsAjaxRequest';

/* 查询是否开启进店有礼功能 */
const score = (data) => {
    return axios.request({
        url: 'api/yun/queryscore',
        method: 'post',
        data
    });
};

/* 获取进店有礼礼品的二维码 */
const giftQrcode = (data) => {
    return axios.request({
        url: 'api/yun/getqrcode',
        method: 'post',
        data,
    });
};

/* 获取参与抽奖的商品 */
const luckyDrawGift = (data) => {
    return axios.request({
        url: 'api/yun/getproducts',
        method: 'post',
        data,
    });
};

/* 获取进店有礼的获奖记录 */
const giftHistory = (data) => {
    return axios.request({
        url: 'api/yun/getawardrecord',
        method: 'post',
        data,
    });
};

/* 获取微信jssdk签名 */
const jsSing = () => {
    return axios.request({
        url: 'https://api.liangzhukeji.com/api/product/weixin/jsSign',
        method: 'get'
    });
};

export{
    score,
    giftQrcode,
    luckyDrawGift,
    giftHistory,
    jsSing
}