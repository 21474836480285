<template>
    <div id="eggs" :class="isVertical ? 'eggsVertical' : 'eggs'">
        <div v-if="!isVertical" class="title">
            <img src="../images/eggs_title.png">
        </div>
        <ul :class="isVertical ? 'eggs eggsVertical' : 'eggs'">
            <li v-for="(item, index) in 3" :key="index" @click="drown(index)">
                <div v-show="hammer && (active == index)" :class="(hammer && (active == index)) ? 'hammer hammerClick' : 'hammer'">
                    <img src="../images/hammer.png"/>
                </div>
                <!-- <div :class="hammer ? 'hammer hammerClick' : 'hammer'">
                    <img src="../images/hammer.png"/>
                </div> -->
                <div class="bottom">
                    <img src="../images/bottom.png"/>
                </div>
                <div class="content"  v-if="!smashOpen && (active == index)">
                    <img v-show="smashOpen" class="front" src="../images/front.png"/>
                    <img v-show="!smashOpen" class="behind" src="../images/behind.png"/>
                </div>
                <div class="content" v-else>
                    <img class="front" src="../images/front.png"/>
                </div>

                <img v-if="ray && (active == index)" class="ray" src="../images/egg_ray.png">
                <!-- <img class="blast" src="../images/blast.gif"> -->
                <img v-if="blast && (active == index)" :class="blastName(index)" src="../images/blast.gif">
            </li>
        </ul>

        <div v-if="isVertical" class="giftList giftListVertical">
            <div>
                <h4>奖品清单</h4>
                <div class="list">
                    <ul :class="{scroll: animate}">
                        <li v-for="(item, index) in list" :key="index">
                            <img :src="item.ImgUrl"/>
                            <p>{{item.MallProductName}}</p>
                        </li>
                    </ul>

                </div>
            </div>
        </div>
        <div v-else class="giftList">
            <div>
                <h4>
                    <p>奖品</p>
                    <p>清单</p>
                </h4>
                <div class="list">
                    <ul :class="{scroll: animate}">
                        <li v-for="(item, index) in list" :key="index">
                            <img :src="item.ImgUrl"/>
                            <p>{{item.MallProductName}}</p>
                        </li>
                    </ul>

                </div>
            </div>
        </div>

        <div v-if="isVertical" class="history historyVertical">
            <h4>中奖名单</h4>
            <div>
                <div>
                    <p>用户名</p>
                    <p>砸得奖品</p>
                </div>
                <ul :class="{scroll: historyAnimate}" v-if="history">
                    <li v-for="(item, index) in history" :key="index">
                        <p>{{item.membername}}</p>
                        <p>砸出 {{item.poductname}}</p>
                    </li>
                    <!-- <li v-for="(item, index) in history" :key="index">
                        <p>{{item.body.membername}}</p>
                        <p>砸出 {{item.body.poductname}}</p>
                    </li> -->
                </ul>
            </div>
        </div>
        <div v-else class="history">
            <h4>中奖名单</h4>
            <div>
                <div>
                    <p>用户名</p>
                    <p>砸得奖品</p>
                </div>
                <ul :class="{scrollV: historyAnimate}" v-if="history">
                    <li v-for="(item, index) in history" :key="index">
                        <p>{{item.membername}}</p>
                        <p>砸出 {{item.poductname}}</p>
                    </li>
                    <!-- <li v-for="(item, index) in history" :key="index">
                        <p>{{item.body.membername}}</p>
                        <p>砸出 {{item.body.poductname}}</p>
                    </li> -->
                </ul>
            </div>
        </div>

        <van-popup v-model="popupShow" :class="isVertical ? 'popupShow popupShowVertical' : 'popupShow'">
            <div class="box giftShow" @click="close(1)">
                <img class="fireworks" :src="`${fireworks}?${time}`"/>
                <div class="code">
                    <div>
                        <img :src="activeGift.ImgUrl"/>
                        <p>{{activeGift.MallProductName}}</p>
                    </div>
                </div>
                <div class="product">
                    <img class="giftCode" :src="giftCode"/>
                    <div>
                        <img class="giftTxt" src="../images/giftTxt.png"/>
                       <!--  <p>哇哦！人有精气神，好运从天降！恭喜你，你被大奖砸中啦！</p>
                        <p>快来扫码领取吧</p> -->
                    </div>
                </div>
            </div>
        </van-popup>

        <van-popup v-model="nogiftShow" :class="isVertical ? 'popupShow popupShowVertical' : 'popupShow'" @close="close">
            <div class="box" @click="close(2)">
                <div class="code">
                    <div>
                        <img src="../images/nogift.png"/>
                    </div>
                </div>
                <div :class="isVertical ? 'product nogiftVertical' : 'product nogift'">
                    <div>
                        <img class="noGiftTxt" src="../images/noGiftTxt.png"/>
                        <!-- <p>很遗憾！</p>
                        <p>您没有中奖哦~</p> -->
                    </div>
                </div>
            </div>
        </van-popup>

         <audio src="../images/bg.mp3" loop ref="bg" class="myAudio"></audio>
         <audio src="../images/hammer.mp3" ref="hammer" class="myAudio"></audio>
         <audio src="../images/gift.mp3" ref="gift" class="myAudio"></audio>
         <audio src="../images/nogift.mp3" ref="nogift" class="myAudio"></audio>
       
       <button :class="playBgMp3 ? 'audioBtn audio02' : 'audioBtn audio01'" @click="play()"></button>
    </div>
</template>

<script>
import {luckyDrawGift, giftQrcode, giftHistory} from '../api/eggs'
export default {
    data () {
        return {
            token: '',
            animate: false, //清单滚动
            historyAnimate: false, //历史记录滚动
            active: '',
            list:[
                {name: 1},
                {name: 2},
                {name: 3},
                {name: 4},
                {name: 5},
                {name: 6},
                {name: 7},
                {name: 8},
                {name: 9},
            ],
            smashOpen: true, //蛋未砸开
            ray: false, //光
            blast: false, //爆炸
            isDrown: true, //是否点击
            hammer: false, //锤子
            activeGift: '',
            giftCode: '',
            popupShow: false,// 中奖弹窗
            nogiftShow: false, //未中奖弹窗
            history: [
               /*  {membername: '赵**', poductname:'长嘴狗除醛玩偶'},
                {membername: '王**', poductname:'除醛碳包'},
                {membername: '孙**', poductname:'长嘴狗除醛玩偶'},
                {membername: '周**', poductname:'新房清洁套装'},
                {membername: '陈**', poductname:'除醛碳包'},
                {membername: '赵**', poductname:'长嘴狗除醛玩偶'}, */
            ],
            isVertical: '', //是否为竖屏
            playBgMp3: false,
            time: '',
            fireworks: require('../images/gift02.gif'),
        }
    },
    mounted () {
        const {token,vertical} = this.$route.query;
        vertical && (this.isVertical = vertical);
        this.token = token;
        this.getGift();
        setInterval(this.historyScroll, 1000);
        console.log(this.$refs);
        
    },
    computed: {
        blastName () {
            return (index) => {
                switch(index) {
                    case 0: return 'blast blast0';
                    case 1: return 'blast blast1';
                    case 2: return 'blast blast2';
                }
            }
        }
    },
    methods: {
        test () {
        document.querySelector('.myAudio').play();

        },
        /* 历史记录无缝滚动 */
        historyScroll () {
            this.historyAnimate = true;
            setTimeout(() => {
                this.history.push(this.history[0]);
                this.history.shift();
                this.historyAnimate = false;
            }, 500)
        },

        /* 无缝滚动 */
        listScroll () {
            this.animate = true;
            setTimeout(() => {
                this.list.push(this.list[0]);
                this.list.shift();
                this.animate = false;
            }, 500)
        },

        /* 获取参与抽奖的礼品 */
        async getGift () {
            const {token} = this;
            let res = await luckyDrawGift({token});
            console.log(res);
            const {status, data, message} = res;
            if (status == 0) {
                this.list = data.data;
                this.randomHistory(data.data);
            } else {
                this.$toast(message);
            }
        },

        /* randomHistory */
        randomHistory (data) {
            var prefixArray = new Array("赵", "钱", "杨", "陈", "王", "周", "常", "张", "黄", "孙");
            for (var i=0; i<30; i++) {
                var membername = `${prefixArray[parseInt(10*Math.random())]}**`;
                var poductname = data[Math.floor(Math.random() * data.length)].MallProductName;
                this.history.push({membername, poductname})
            }
        },

        /* 砸下动作 */
        drown (index) {
            const {isDrown} = this;
            
            if (isDrown) {
                this.hammer = true;
                this.active = index;
                this.timeOut(() => {
                    console.log('123');
                    this.isDrown = false;
                    this.smashOpen = false;
                    this.hammer = false;
                    this.$refs.hammer.play();
                    this.blast = true;
                    this.ray = true;
                }, 1000)
                .then(() => {
                    this.timeOut(() => {
                        this.luckDraw();
                        this.blast = false;
                    },1000);
                })
            }
        },

        timeOut (cb, time) {
            return new Promise((resolve) => {
                let a = setTimeout(() => {
                    cb && cb();
                    clearTimeout(a);
                    resolve();
                }, time || 0);
            })
        },

        /* 抽奖 */
        async luckDraw() {
            const {list, token} = this;
            let num = parseInt(3*Math.random());
            this.activeGift = this.list[num];
            let arr = {
                token,
                ProductId: this.activeGift.MallProductId
            }
            let res = await giftQrcode(arr);
            console.log(res);
            const {status, data} = res;
            if (status == 0) {
                this.giftCode = data.qrcode;
                this.$refs.gift.play();
                this.time = new Date().getTime();
                this.popupShow = true;
            } else{
                this.nogiftShow = true;
                this.$refs.nogift.play();
                this.active = '';
                this.smashOpen = true;
                this.ray = false;
                this.isDrown = true;
                this.hammer = false;
                this.activeGift = '';
                this.giftCode = '';
            }
        },

        /* 取消弹窗 */
        close (num) {
            num == 1 ?
            this.popupShow = false:
            this.nogiftShow = false;
            this.active = '';
            this.smashOpen = true;
            this.ray = false;
            this.isDrown = true;
            this.hammer = false;
            this.activeGift = '';
            this.giftCode = '';
            // this.getHistory();
        },

        /* 获取抽奖记录 */
        async getHistory () {
            const {token} = this;
            let res = await giftHistory({token});
            console.log(res);
            const {status, data, message} = res;
            if (status == 0) {
                this.history = data;
                this.history.map(item => {
                    item.body = JSON.parse(item.body);
                });
                console.log(this.history);
            }
        },

        play () {
            const {playBgMp3} = this;
            if (playBgMp3) {
                this.playBgMp3 = false;
                this.$refs.bg.pause();
            } else {
                this.playBgMp3 = true;
                this.$refs.bg.play();
            }
        }
    }
}
</script>

<style lang="less" src="@/style/eggs.less">

</style>