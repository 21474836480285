import Axios from 'axios'

const instance = Axios.create({
    baseURL: 'https://www.hnliangzhu.top/'
})

instance.interceptors.request.use(config => {
    // console.log(config);
    return config;
});

instance.interceptors.response.use(
    res => {
    if (res.data.status == -1 ){
        window.android.getShopToken()
    }
    return res.data;
},
err => {
    window.android.getShopToken();
    return err;
}
);

export default instance;